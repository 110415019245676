import { createAuth0 } from '@auth0/auth0-vue';
import { SessionStorage } from 'quasar';
import { boot } from 'quasar/wrappers';
import { DlaClient } from 'src/services/dla-api';
import { watch } from 'vue';

export const auth0 = createAuth0({
  domain: 'auth.dl-academy.tech',
  clientId: process.env.DEBUGGING
    ? '8PaToKktIO8DZV7pjWOfzmiVEaU7mBnT'
    : 'NdGwMlGkC7EjpMx1E7Gvk33foNHQhpn8',
  cacheLocation: 'localstorage',
  authorizationParams: {
    redirect_uri: window.location.origin,
    callback_url: window.location.origin,
    audience: 'dla',
  },
});

function checkAuth(to, client, router) {
  if (to.meta.open) {
    return;
  }
  watch(client.isLoading, async (loading) => {
    if (!loading) {
      if (!client.isAuthenticated.value) {
        if (to.path && !['/', '/login'].includes(to.path)) {
          SessionStorage.set('deepLinkUrl', to.path);
        }
        console.log('Not authenticated, redirecting');
        if (!SessionStorage.getItem('user')) {
          if (to.path !== '/auto-login/') {
            router.push({
              path: '/login',
              query: to.query,
            });
          }
        } else if (['/', '/login'].includes(to.path)) {
          router.push('/lna');
        }
      } else if (['/', '/login'].includes(to.path)) {
        console.log('Authenticated, redirecting');
        if (!SessionStorage.getItem('user')) {
          const user = await new DlaClient().get('/users/identify');
          SessionStorage.set('user', user);
        }

        const user = SessionStorage.getItem('user');
        if (
          user?.permissions?.indexOf('admin') > -1 ||
          user?.permissions?.indexOf('user') > -1
        ) {
          router.push('/learning/dashboard');
        } else if (user?.permissions?.indexOf('customer') > -1) {
          router.push('/reports');
        }
      } else {
        console.log(to.path);
      }
    }
  });
}

export default boot(async ({ app, router }) => {
  app.use(auth0);
  router.beforeEach((to) => {
    checkAuth(to, auth0, router);
    if (auth0.isAuthenticated.value) {
      // Get the deep link URL from the session storage
      const deepLinkUrl = SessionStorage.getItem('deepLinkUrl');
      if (deepLinkUrl) {
        // Convert deepLinkUrl to string before passing it to router.push() with a 1 second timeout
        router.push(String(deepLinkUrl));
        // Clear the deep link URL from the session storage
        SessionStorage.remove('deepLinkUrl');
      }
    }
  });
});
